import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { 
  FaLaptopCode, FaPalette, FaMobileAlt, FaNetworkWired, FaServer, 
  FaVideo, FaSchool, FaCalendarAlt, FaCloud, FaQuoteLeft,
  FaMapMarkerAlt, FaEnvelope, FaPhone, FaCheck
} from 'react-icons/fa';

const SinglePage = () => {
  // Services data
  const services = [
    {
      id: 1,
      title: 'Website Development',
      description: 'Custom website development using modern technologies to create responsive, user-friendly, and high-performing websites.',
      icon: <FaLaptopCode />
    },
    {
      id: 2,
      title: 'Web Design',
      description: 'Creative and intuitive web designs that enhance user experience and effectively communicate your brand message.',
      icon: <FaPalette />
    },
    {
      id: 3,
      title: 'Mobile Application Development',
      description: 'Development of native and cross-platform mobile applications for iOS and Android devices.',
      icon: <FaMobileAlt />
    },
    {
      id: 4,
      title: 'Computer Network Designing',
      description: 'Design and implementation of secure and efficient computer networks for businesses of all sizes.',
      icon: <FaNetworkWired />
    },
    {
      id: 5,
      title: 'Server Installation',
      description: 'Professional server installation and configuration services to ensure optimal performance and security.',
      icon: <FaServer />
    },
    {
      id: 6,
      title: 'Surveillance Cameras',
      description: 'Installation and maintenance of surveillance camera systems for enhanced security and monitoring.',
      icon: <FaVideo />
    },
    {
      id: 7,
      title: 'School Management System',
      description: 'Comprehensive school management systems (SIS, SMS, LMS) to streamline educational administration and enhance learning.',
      icon: <FaSchool />
    },
    {
      id: 8,
      title: 'CRM - Organise Events and More',
      description: 'Customer Relationship Management solutions to help businesses organize events, manage contacts, and improve customer relations.',
      icon: <FaCalendarAlt />
    },
    {
      id: 9,
      title: 'Server and Website Hosting',
      description: 'Reliable server and website hosting services with excellent uptime, security, and technical support.',
      icon: <FaCloud />
    }
  ];

  // Portfolio projects
  const projects = [
    {
      id: 1,
      title: 'LSST',
      category: 'education',
      bgColor: '#2c3e50',
      url: 'http://lsst.ac',
      description: 'Website and School Management System'
    },
    {
      id: 2,
      title: 'FSB',
      category: 'education',
      bgColor: '#3498db',
      url: 'http://fairfield.ac',
      description: 'Website and School Management System'
    },
    {
      id: 3,
      title: 'Everlasting Brows',
      category: 'ecommerce',
      bgColor: '#e74c3c',
      url: 'http://everlastingbrows.com',
      description: 'E-commerce Website'
    },
    {
      id: 4,
      title: 'Sleekbrows',
      category: 'ecommerce',
      bgColor: '#9b59b6',
      url: 'http://sleekbrows.com',
      description: 'E-commerce Website'
    },
    {
      id: 5,
      title: 'Signet Contemporary Art',
      category: 'business',
      bgColor: '#f39c12',
      url: 'http://signetcontemporaryart.com',
      description: 'Art Gallery Website'
    }
  ];

  // Testimonials data
  const testimonials = [
    {
      id: 1,
      content: "LSST and its associates companies has received remarkable services and support from GDWD. GDWD managed to build and replace our old database software to new database system which has improved much further and better facilities compare to all other software systems. I tried many other companies and softwares but I found that designed software by GDWD is excellent. We always find GDWD available when ever we need support.",
      client: "LSST",
      position: "London School of Science & Technology"
    }
  ];

  // Scroll to section function
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 80,
        behavior: 'smooth'
      });
    }
  };

  return (
    <>
      {/* Hero Section */}
      <section id="home" className="hero">
        <div className="hero-bg"></div>
        <div className="hero-content">
          <h1>Where Everything is Possible</h1>
          <p>
            We are a team of freelance developers specializing in website development, 
            web design, mobile applications, and various IT services.
          </p>
          <div>
            <Button 
              variant="primary" 
              className="me-3"
              onClick={() => scrollToSection('contact')}
            >
              Get in Touch
            </Button>
            <Button 
              variant="outline-light"
              onClick={() => scrollToSection('services')}
            >
              Our Services
            </Button>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="section">
        <Container>
          <div className="section-title">
            <h2>Who We Are</h2>
          </div>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="about-content">
                <h3>Freelance Developers</h3>
                <p>
                  We are a team of skilled freelance developers with expertise in various 
                  technologies and domains. Our mission is to provide high-quality digital 
                  solutions that help businesses grow and succeed in the digital world.
                </p>
                <p>
                  With years of experience in the industry, we have worked with clients 
                  from various sectors, delivering custom solutions that meet their specific needs.
                </p>
                <div className="mt-4">
                  <h4>Why Choose Us</h4>
                  <Row className="mt-3">
                    <Col md={6}>
                      <div className="d-flex mb-3">
                        <div className="me-3 text-primary">
                          <FaCheck />
                        </div>
                        <div>
                          <p className="mb-0"><strong>Expertise</strong> in multiple technologies</p>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="d-flex mb-3">
                        <div className="me-3 text-primary">
                          <FaCheck />
                        </div>
                        <div>
                          <p className="mb-0"><strong>Customized solutions</strong> for your needs</p>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="d-flex mb-3">
                        <div className="me-3 text-primary">
                          <FaCheck />
                        </div>
                        <div>
                          <p className="mb-0"><strong>Timely delivery</strong> of projects</p>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="d-flex mb-3">
                        <div className="me-3 text-primary">
                          <FaCheck />
                        </div>
                        <div>
                          <p className="mb-0"><strong>Ongoing support</strong> after completion</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="about-image">
                <div 
                  className="img-fluid rounded shadow"
                  style={{ 
                    width: '100%', 
                    height: '400px',
                    background: 'linear-gradient(135deg, #2c3e50, #3498db)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white'
                  }}
                >
                  <div className="text-center">
                    <h3>GDWD Team</h3>
                    <p>Freelance Developers</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Services Section */}
      <section id="services" className="section bg-light">
        <Container>
          <div className="section-title">
            <h2>What We Offer</h2>
          </div>
          <div className="services-grid">
            {services.map(service => (
              <div className="services-card" key={service.id}>
                <div className="icon">{service.icon}</div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            ))}
          </div>
        </Container>
      </section>

      {/* Portfolio Section */}
      <section id="portfolio" className="section">
        <Container>
          <div className="section-title">
            <h2>How We Do</h2>
          </div>
          <div className="portfolio-grid">
            {projects.map(project => (
              <div className="portfolio-item" key={project.id}>
                <div 
                  style={{ 
                    width: '100%', 
                    height: '250px', 
                    backgroundColor: project.bgColor,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white'
                  }}
                >
                  <h2>{project.title}</h2>
                </div>
                <div className="overlay">
                  <h3>{project.title}</h3>
                  <p>{project.description}</p>
                  <a href={project.url} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-light mt-2">View Project</a>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section>

      {/* Process Section */}
      <section className="section bg-light">
        <Container>
          <div className="section-title">
            <h2>Our Process</h2>
          </div>
          <Row className="justify-content-center">
            <Col lg={10}>
              <div className="process-timeline">
                <div className="process-item">
                  <div className="process-number">1</div>
                  <div className="process-content">
                    <h3>Consultation</h3>
                    <p>
                      We begin by understanding your requirements, goals, and challenges
                      through detailed discussions and requirement gathering sessions.
                    </p>
                  </div>
                </div>
                
                <div className="process-item">
                  <div className="process-number">2</div>
                  <div className="process-content">
                    <h3>Planning</h3>
                    <p>
                      Based on the consultation, we develop a comprehensive plan outlining
                      the scope, timeline, deliverables, and resources required for your project.
                    </p>
                  </div>
                </div>
                
                <div className="process-item">
                  <div className="process-number">3</div>
                  <div className="process-content">
                    <h3>Design</h3>
                    <p>
                      Our designers create wireframes and prototypes to visualize the solution
                      and get your feedback before moving to the development phase.
                    </p>
                  </div>
                </div>
                
                <div className="process-item">
                  <div className="process-number">4</div>
                  <div className="process-content">
                    <h3>Development</h3>
                    <p>
                      Our development team brings the design to life using the latest technologies
                      and best practices to ensure high-quality, efficient, and secure solutions.
                    </p>
                  </div>
                </div>
                
                <div className="process-item">
                  <div className="process-number">5</div>
                  <div className="process-content">
                    <h3>Testing</h3>
                    <p>
                      We conduct thorough testing to identify and fix any issues, ensuring
                      the solution meets all requirements and quality standards.
                    </p>
                  </div>
                </div>
                
                <div className="process-item">
                  <div className="process-number">6</div>
                  <div className="process-content">
                    <h3>Deployment</h3>
                    <p>
                      Once testing is complete, we deploy the solution to your production
                      environment, ensuring a smooth transition and minimal disruption.
                    </p>
                  </div>
                </div>
                
                <div className="process-item">
                  <div className="process-number">7</div>
                  <div className="process-content">
                    <h3>Support & Maintenance</h3>
                    <p>
                      We provide ongoing support and maintenance to ensure your solution
                      continues to perform optimally and remains up-to-date.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Testimonials Section */}
      <section id="testimonials" className="section">
        <Container>
          <div className="section-title">
            <h2>Testimonials</h2>
          </div>
          <div className="testimonials-slider">
            {testimonials.map(testimonial => (
              <div className="testimonials-item" key={testimonial.id}>
                <div className="quote-icon mb-4">
                  <FaQuoteLeft size={30} className="text-primary" />
                </div>
                <p>"{testimonial.content}"</p>
                <div className="client">{testimonial.client}</div>
                <div className="text-muted">{testimonial.position}</div>
              </div>
            ))}
          </div>
        </Container>
      </section>

      {/* Stats Section */}
      <section className="section stats-section text-center">
        <Container>
          <Row>
            <Col md={3} className="mb-4 mb-md-0">
              <div className="stat-item">
                <h2 className="stat-number">100+</h2>
                <p className="stat-title">Happy Clients</p>
              </div>
            </Col>
            <Col md={3} className="mb-4 mb-md-0">
              <div className="stat-item">
                <h2 className="stat-number">200+</h2>
                <p className="stat-title">Projects Completed</p>
              </div>
            </Col>
            <Col md={3} className="mb-4 mb-md-0">
              <div className="stat-item">
                <h2 className="stat-number">10+</h2>
                <p className="stat-title">Years Experience</p>
              </div>
            </Col>
            <Col md={3}>
              <div className="stat-item">
                <h2 className="stat-number">24/7</h2>
                <p className="stat-title">Support</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Contact Section */}
      <section id="contact" className="section">
        <Container>
          <div className="section-title">
            <h2>Contact Us</h2>
            <p className="lead text-center mb-5">
              Ready to discuss your project? We're here to help you bring your vision to life.
            </p>
          </div>

          <Row className="justify-content-center">
            <Col lg={10} className="mb-5">
              <div className="contact-card p-0 bg-white rounded shadow">
                <div className="row g-0">
                  <div className="col-md-5 bg-primary text-white p-4 d-flex flex-column justify-content-center">
                    <div className="px-4 py-5">
                      <h3 className="mb-4 fw-bold">Get in Touch</h3>
                      <p className="mb-5 opacity-75">
                        We're always looking for new projects and opportunities. If you'd like to work with us, 
                        please get in touch with us today.
                      </p>
                      <div className="mt-5 pt-4">
                        <div className="d-flex align-items-center mb-4">
                          <div className="icon me-3">
                            <FaEnvelope size={20} />
                          </div>
                          <a href="mailto:info@gdwd.co.uk" className="text-white text-decoration-none">info@gdwd.co.uk</a>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="icon me-3">
                            <FaMapMarkerAlt size={20} />
                          </div>
                          <p className="mb-0">Liepdārzi 15, Baldones nov. LV-2125</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 p-5">
                    <h4 className="mb-4 fw-bold">Contact Information</h4>
                    <div className="contact-info">
                      <div className="info-item d-flex mb-4">
                        <div className="icon me-3 text-primary">
                          <FaPhone size={24} />
                        </div>
                        <div>
                          <h5 className="fw-bold">Phone</h5>
                          <p className="mb-0">Contact us via email for phone details</p>
                        </div>
                      </div>
                      
                      <div className="info-item d-flex mb-4">
                        <div className="icon me-3 text-primary">
                          <FaEnvelope size={24} />
                        </div>
                        <div>
                          <h5 className="fw-bold">Email</h5>
                          <p className="mb-0"><a href="mailto:info@gdwd.co.uk" className="text-decoration-none">info@gdwd.co.uk</a></p>
                        </div>
                      </div>
                      
                      <div className="info-item d-flex mb-4">
                        <div className="icon me-3 text-primary">
                          <FaMapMarkerAlt size={24} />
                        </div>
                        <div>
                          <h5 className="fw-bold">Location</h5>
                          <p className="mb-0">Liepdārzi 15, Baldones nov. LV-2125</p>
                        </div>
                      </div>
                      
                      <div className="info-item d-flex">
                        <div className="icon me-3 text-primary">
                          <FaCalendarAlt size={24} />
                        </div>
                        <div>
                          <h5 className="fw-bold">Business Hours</h5>
                          <p className="mb-1">Monday - Friday: 9:00 AM - 6:00 PM</p>
                          <p className="mb-1">Saturday: By appointment</p>
                          <p className="mb-0">Sunday: Closed</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Map Section */}
      <section className="section bg-light">
        <Container>
          <div className="section-title">
            <h2>Our Location</h2>
          </div>
          <div className="map-container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="rounded shadow overflow-hidden">
                  <div className="map-placeholder bg-primary text-white d-flex align-items-center justify-content-center" style={{ height: '450px' }}>
                    <div className="text-center px-4">
                      <div className="display-4 mb-4">
                        <FaMapMarkerAlt />
                      </div>
                      <h3 className="mb-3">Liepdārzi 15, Baldones nov. LV-2125</h3>
                      <p className="lead">
                        We're located in a convenient area with easy access to major transportation routes.
                        In a real application, an interactive Google Map would be embedded here.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      {/* CTA Section */}
      <section className="section cta-section text-center">
        <Container>
          <h2>Ready to Start Your Project?</h2>
          <p className="mb-4">Contact us today to discuss your requirements and get a free quote.</p>
          <Button 
            variant="primary" 
            size="lg"
            onClick={() => scrollToSection('contact')}
          >
            Contact Us
          </Button>
        </Container>
      </section>
    </>
  );
};

export default SinglePage;