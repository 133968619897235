import React, { useState, useEffect } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
      
      // Update active section based on scroll position
      const sections = ['home', 'about', 'services', 'portfolio', 'testimonials', 'contact'];
      const scrollPosition = window.scrollY + 100;
      
      for (const section of sections) {
        const element = document.getElementById(section);
        if (element) {
          const offsetTop = element.offsetTop;
          const height = element.offsetHeight;
          
          if (scrollPosition >= offsetTop && scrollPosition < offsetTop + height) {
            setActiveSection(section);
            break;
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 80,
        behavior: 'smooth'
      });
    }
  };

  return (
    <Navbar 
      expand="lg" 
      fixed="top" 
      className={`${scrolled ? 'scrolled' : ''}`}
      bg={scrolled ? 'dark' : 'transparent'} 
      variant={scrolled ? 'dark' : 'light'}
    >
      <Container>
        <Navbar.Brand href="#home" onClick={(e) => { e.preventDefault(); scrollToSection('home'); }}>
          <img 
            src="/images/logo.svg" 
            alt="GDWD Logo" 
            height="30" 
            className="d-inline-block align-top me-2"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link 
              href="#home" 
              className={activeSection === 'home' ? 'active' : ''}
              onClick={(e) => { e.preventDefault(); scrollToSection('home'); }}
            >
              Home
            </Nav.Link>
            <Nav.Link 
              href="#about" 
              className={activeSection === 'about' ? 'active' : ''}
              onClick={(e) => { e.preventDefault(); scrollToSection('about'); }}
            >
              Who we are
            </Nav.Link>
            <Nav.Link 
              href="#services" 
              className={activeSection === 'services' ? 'active' : ''}
              onClick={(e) => { e.preventDefault(); scrollToSection('services'); }}
            >
              What we offer
            </Nav.Link>
            <Nav.Link 
              href="#portfolio" 
              className={activeSection === 'portfolio' ? 'active' : ''}
              onClick={(e) => { e.preventDefault(); scrollToSection('portfolio'); }}
            >
              How we do
            </Nav.Link>
            <Nav.Link 
              href="#testimonials" 
              className={activeSection === 'testimonials' ? 'active' : ''}
              onClick={(e) => { e.preventDefault(); scrollToSection('testimonials'); }}
            >
              Testimonials
            </Nav.Link>
            <Nav.Link 
              href="#contact" 
              className={activeSection === 'contact' ? 'active' : ''}
              onClick={(e) => { e.preventDefault(); scrollToSection('contact'); }}
            >
              Contact us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
